<template>
  <Transition name="fade">
    <dialog
      v-if="active && content"
      ref="dialog"
      class="geoip-locale-switcher"
      :open="active"
    >
      <div class="geoip-locale-switcher__header">
        <span>{{ content.header }}</span>

        <ButtonBase
          class="geoip-locale-switcher__button geoip-locale-switcher__button--close"
          data-gtm="localeSwitchPopup__close"
          @click="close"
        >
          <SvgIconCross />
        </ButtonBase>
      </div>

      <div class="geoip-locale-switcher__body">
        <T3HtmlParser
          class="geoip-locale-switcher__content"
          :content="content.bodytext"
        />

        <div class="geoip-locale-switcher__cta">
          <ButtonBase
            class="geoip-locale-switcher__button geoip-locale-switcher__button--primary"
            data-gtm="localeSwitchPopup__stay"
            @click="close"
          >
            <T3HtmlParser :content="content.currentLocale.text" />
          </ButtonBase>

          <ButtonBase
            class="geoip-locale-switcher__button geoip-locale-switcher__button--secondary"
            :button="{ link: content.suggestedLocale.link }"
            data-gtm="localeSwitchPopup__change"
          >
            <T3HtmlParser :content="content.suggestedLocale.text" />
          </ButtonBase>
        </div>
      </div>
    </dialog>
  </Transition>
</template>

<script setup lang="ts">
import { nextTick, ref, watch } from 'vue'

import type { BaseGeoipLocaleSwitcherProps } from '~/types'
import SvgIconCross from '~ui/assets/icons/icon-cross.svg'
import { ButtonBase } from '~ui/components'

const props = defineProps<BaseGeoipLocaleSwitcherProps>()

const dialog = ref<HTMLDialogElement | null>()

watch(
  () => props.active,
  (state) => {
    nextTick(() => {
      state ? dialog.value?.show() : dialog.value?.close()
    })
  }
)

const emit = defineEmits(['close'])
const close = () => {
  emit('close')
}
</script>

<style lang="scss">
.geoip-locale-switcher {
  $border-color: rgba(color(tarawera), 10%);

  position: fixed;
  bottom: rem($container-padding);
  inset-inline: rem($container-padding);
  z-index: z-index(over);
  width: auto;
  font-weight: 600;
  font-size: rem(18px);
  color: color(tarawera);
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: 0 10px 18px rgba(color(black), 40%);

  @include media-query(sm) {
    max-width: rem(400px);
  }

  &__header {
    padding: rem(20px);
    border: 1px solid $border-color;
  }

  &__body {
    border: 1px solid $border-color;
  }

  &__content,
  &__cta {
    padding: rem(20px);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & span {
      text-transform: uppercase;
    }
  }

  &__content span {
    font-weight: 700;
    text-transform: capitalize;
    text-decoration: underline;
  }

  &__cta {
    display: grid;
    gap: rem(24px);
  }

  &__button {
    margin: 0;

    & > span {
      padding: rem(20px);
      height: auto;
      font-weight: 600;

      & > div > span {
        font-weight: 700;
      }
    }

    &--close {
      @include button-style(color(tarawera), color(white), 2.5, $border-color);
      border-width: 1px;
      aspect-ratio: 1;

      & > span {
        pointer-events: none;
        display: grid;
        place-items: center;
        padding: rem(10px);

        & > svg {
          height: 24px;
          width: 24px;

          & path {
            fill: color(tarawera);
          }
        }
      }
    }

    &--primary {
      @include button-style(color(tarawera), color(white), 2.5);
    }

    &--secondary {
      @include button-style(color(white), color(tarawera), 2.5);
    }
  }
}
</style>
